export function ExecuteTimeout(timeout: number) {
  let _intervals = null;

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;

    descriptor.value = function () {
      const _self = this;
      const _args = arguments;

      clearTimeout(_intervals);
      _intervals = setTimeout(() => method.apply(_self, _args), timeout);
    };
  };
}
