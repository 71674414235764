import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SindicatoModule } from '../app/apps/sindicato/sindicato.module';
import { environment } from '../environments/environment';

if (environment.production) {
    enableProdMode();
}

document.title = 'Gestão Sindical';

window.addEventListener('dragover', event => event.preventDefault(), false);
window.addEventListener('drop', event => event.preventDefault(), false);

platformBrowserDynamic().bootstrapModule(SindicatoModule);
