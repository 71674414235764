import { Component, Injector } from '@angular/core';

import { BaseLayoutComponent } from '../shared/base-layout.component';
import { ToastrMsgService } from 'src/app/shared/services';
import { showErrorInToastr } from 'src/app/shared/utils';

import { SynchronizeApiService } from 'src/app/apps/sindicato/services/synchronize.service';
import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './sindicato-layout.component.html',
  providers: [SynchronizeApiService, ToastrMsgService]
})
export class SindicatoLayoutComponent extends BaseLayoutComponent {

  constructor(
    protected injector: Injector,
    private syncService: SynchronizeApiService,
    private toastrService: ToastrMsgService
  ) {
    super(injector, navItems);
  }

  sync(): void {
    this.syncService.sync().subscribe(data => {
      if (data.ok) {
        this.toastrService.success('Processo de sincronização iniciado!');
      }
    }, error => {
      showErrorInToastr(this.toastrService, error);
    });
  }
}
