import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationApiService } from 'src/app/shared/services';

@Injectable()
export class SynchronizeApiService extends ApplicationApiService {

    constructor(protected injector: Injector) {
        super(injector);
    }

    public sync(): Observable<any> {
        return this.http.post('/api/synchronizer/sync/', {}).pipe(
            map(res => res)
        );
    }
}
