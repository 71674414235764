import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { copy } from 'src/app/shared/utils';

const COMMON = {
  // Valores padrões para todas as aplicações
};

// Empresas no Geral
const OUTRAS_EMPRESAS = {
  'Membros': 'Assinantes',
  'membros': 'Assinantes',
  'Membro': 'Assinante',
  'membro': 'Assinante',
};

// 86.30 => Atividades de Odontologia
const ATIVIDADES_ODONTOLOGIA = {
  'Membros': 'Pacientes',
  'membros': 'pacientes',
  'Membro': 'Paciente',
  'membro': 'paciente',
};

// 86.50 => Atividades de Fisioterapia
const ATIVIDADES_FISIOTERAPIA = {
  'Membros': 'Alunos',
  'membros': 'alunos',
  'Membro': 'Aluno',
  'membro': 'aluno',
};

// 94 => Organizações Associativas
const ORGANIZACOES_ASSOCIATIVAS = {
  'Membros': 'Sócios',
  'membros': 'sócios',
  'Membro': 'Sócio',
  'membro': 'sócio',
};

// 94C => Organizações Associativas - Clubes
const ORGANIZACOES_ASSOCIATIVAS_CLUBES = {
  'Membros': 'Sócios',
  'membros': 'sócios',
  'Membro': 'Sócio',
  'membro': 'sócio',
};

// 94S => Organizações Sindicais
const ORGANIZACOES_SINDICAIS = {
  'Membros': 'Sócios',
  'membros': 'sócios',
  'Membro': 'Sócio',
  'membro': 'sócio',
};

// 94A => Organizações Associativas - APAE
const ORGANIZACOES_ASSOCIATIVAS_APAE = {
  'Membros': 'Sócios',
  'membros': 'sócios',
  'Membro': 'Sócio',
  'membro': 'sócio',
  'Clientes': 'Excepcionais',
  'clientes': 'excepcionais',
  'Cliente': 'Excepcional',
  'cliente': 'excepcional',
};

// 94R => Organizações religiosas
const ORGANIZACAES_RELIGIOSAS = {
  'Membros': 'Fiéis',
  'membros': 'fiéis',
  'Membro': 'Fiel',
  'membro': 'fiel',
};

// 94P => Organizações Associativas Privadas
const ORG_ASSOCIATIVAS_PRIV = {
  'Membros': 'Filiados',
  'membros': 'filiados',
  'Membro': 'Filiado',
  'membro': 'filiado',
};

// 94F => Organizações Associativas - Federação
const ORG_ASSOCIATIVAS_FED = {
    'Membros': 'Federados',
    'membros': 'federados',
    'Membro': 'Federado',
    'membro': 'federado',
};

// 94FS => Organizações Associativas - Federação Sindical
const ORG_ASSOCIATIVAS_FED_SINDICAL = {
    'Membros': 'Sindicatos',
    'membros': 'sindicatos',
    'Membro': 'Sindicato',
    'membro': 'sindicato',
    'Federados': 'Sindicatos',
    'federados': 'sindicatos',
    'Federado': 'Sindicato',
    'federado': 'sindicato',
};

// 94M => Lojas Maçonicas
const LOJAS_MACONICAS = {
  'Membros': 'Obreiros',
  'membros': 'obreiros',
  'Membro': 'Obreiro',
  'membro': 'obreiro',
  'Admissão': 'Iniciação',
  'Matrícula': 'CIM',
  'Cônjuge': 'Cunhada',
  'Indicado por': 'Padrinho',
};

const VALUES = {
  '45': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  '46': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  '47': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  // Atividades dos serviços de tecnologia da informação
  '62': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  '63': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  // Atividades Jurídicas e de Contabilidade
  '69': Object.assign(copy(COMMON), OUTRAS_EMPRESAS),
  // Atividades de Odontologia
  '86.30': Object.assign(copy(COMMON), ATIVIDADES_ODONTOLOGIA),
  // Atividades de Fisioterapia
  '86.50': Object.assign(copy(COMMON), ATIVIDADES_FISIOTERAPIA),
  // Organizações Associativas
  '94': Object.assign(copy(COMMON), ORGANIZACOES_ASSOCIATIVAS),
  // Organizações Associativas - Clubes
  '94C': Object.assign(copy(COMMON), ORGANIZACOES_ASSOCIATIVAS_CLUBES),
  // Organizações Sindicais
  '94S': Object.assign(copy(COMMON), ORGANIZACOES_SINDICAIS),
  // Organizações Associativas - APAE
  '94A': Object.assign(copy(COMMON), ORGANIZACOES_ASSOCIATIVAS_APAE),
  // Organizações religiosas
  '94R': Object.assign(copy(COMMON), ORGANIZACAES_RELIGIOSAS),
  // Organizações Associativas Privadas
  '94P': Object.assign(copy(COMMON), ORG_ASSOCIATIVAS_PRIV),
  // Organizações Associativas - Federação
  '94F': Object.assign(copy(COMMON), ORG_ASSOCIATIVAS_FED),
  // Organizações Associativas - Federação Sindical
  '94FS': Object.assign(copy(COMMON), ORG_ASSOCIATIVAS_FED_SINDICAL),
  // Lojas Maçônicas
  '94M': Object.assign(copy(COMMON), LOJAS_MACONICAS),
};

@Injectable({
  providedIn: 'root'
})
export class RenameCaptionService {
  constructor(
    private sessionService: SessionService
  ) { }

  public getCaption(value: string): string {
    const item = VALUES[this.sessionService.data.unit.organization.cnae];
    return !item ? value : item[value] || value;
  }
}
